@import url('https://fonts.googleapis.com/css2?family=DotGothic16&family=Major+Mono+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap');

:root {
    --bgc: #ffd8d8;
    --bgc2: #bbff8e;
    --bgc3: #8eacff;
    --bgc4: #ff8e8e;
    --bggc: rgb(0, 0, 139);

    background-color: #000000;
    background-image: url(../images/brick.png);
}

@font-face {
    font-family: Dot Font;
    src: url(../fonts/dotfont.ttf) format('truetype');
  }

@font-face {
    font-family: Fake Receipt;
    src: url(../fonts/fakereceipt.ttf) format('truetype');
}

body {
    margin: 0;
    position: relative;
    height: auto;
    width: auto;
    /* background: url("../images/graphbg2.png");
    background-color: var(--bgc);
    background-size: cover; */

    background: linear-gradient(var(--bgc), var(--bgc2), var(--bgc3), var(--bgc4));
    background-repeat: no-repeat;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.invert {
    filter: invert(1);
}

.blur {
    filter: blur(4px);
}

.barber-wrapper {
    font-size: 3rem;
    width: 600px;
    font-size: 35px;
    letter-spacing: 2px;
    /* background-color: #000; */
    /* background-color: rgb(255, 231, 201);
    box-shadow:  -8px -6px #00A4BD, 6px 6px #FF7A59; */
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 300px;
    max-width: 600px;

    background: repeating-linear-gradient(
        165deg,
        #4400ff,
        #4400ff 10px,
        #ffce7a 10px,
        #ffce7a 20px
        );
}

.barber-inner {
    display: flex;
    flex-direction: row;
    width: 200%;
    height: 100%;
    /* position: absolute; */
    padding: 0;
}

.barber {
    width: 100%;


    background: repeating-linear-gradient(
    30deg,
    #ff0000,
    #ff0000 10px,
    #fdfdfd00 10px,
    #ffffff00 20px
    );
    padding: 0;
    white-space: nowrap;
    float:left;
    animation: barber 5s linear infinite alternate-reverse;
}

@keyframes barber {
    0% {
        transform: translate(0);
    }
    
    100% {
        transform: translate(-100%);
    }
  }

.about-coverpage-wrapper {
    position: fixed;
    left:0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #0000009c;
    z-index: 10;
}

.about-window {
    width: 80%;
    height: 80%;
    max-width: 550px;
    margin: 0 auto auto auto;
    /* border: 4px dashed black; */
    background: radial-gradient(#ffd89a, #ffb949);
    border-radius: 16px 6px 16px 6px;
    background-image: url(../images/oldpaper1.png);
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    transform-origin: center;
    padding: 40px;

    /* background: repeating-linear-gradient(
  45deg,
  #606dbc,
  #606dbc 10px,
  #465298 10px,
  #465298 20px
); */
}

.about-text-wrap {
    height: 100%;
}

.sig-padding {
    margin-right: 30px;
}

.letter-separator-wrap {
    width: auto;
    height: auto;
    position: relative;
    margin: 0 10px;
    text-align: center;
}

.letter-separator {
    width: 70%;
    max-width: 95vw;
    height: fit-content;
    /* object-fit: contain; */
    /* position: absolute; */
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.5s ease-in-out;
    margin: auto;
}

.art-image:hover {
    transform: rotateY(180deg);
}

.about-coverpage-wrapper h1 {
    font-family: "Fake Receipt", monospace;
    color: #00ff07;
    text-shadow: 0 0 2px #005b03, 2px 2px 16px hsla(0,0%,100%,.5);
    text-align: center;
    font-size: 20px;
    margin: 0 auto 10px auto;
    padding: 2px 3px;
    background: black;
}

.about-window h2 {
    font-family: "Tangerine", cursive;
    font-size: 2.5rem;
    text-align: center;
    margin: 20px 0 0 0;
}

.about-window h3 {
    font-family: "Tangerine", cursive;
    text-align: left;
    font-size: 36px;
    margin: 0 20px;
}

.about-window h4 {
    text-align: right;
    margin: 0 30px 10px 0;
    font-family: "Tangerine", cursive;

    font-size: 36px;
}

.about-window h6 {
    text-align: right;
    margin: 0 20px 30px 0;
}

.about-window h5 {
    color: rgb(0, 0, 0);
    text-shadow: 0 0 2px #e00000, 2px 2px 16px hsla(0,0%,100%,.5);
    font-family: "Tangerine", cursive;
    text-align: center;
    font-size: 30px;
    margin: 10px 50px 0px 50px;
    text-justify: auto;
    letter-spacing: 1.5px;
    font-weight: 700;
}

.about-window p {
    text-shadow: 0 0 2px #000000, 2px 2px 16px hsla(0,0%,100%,.5);
    font-family: "Tangerine", cursive;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin: 0px 30px 0px 30px;
    letter-spacing: 1.5px;
}

.thank-you {
    font-family: "Fake Receipt", monospace;
    text-align: center;
    font-size: 20px;
    margin: 10px 50px 0px 50px;
    letter-spacing: 1.5px;
    padding: 2px 3px;
    background: black;

    color: #ff5858;
    text-shadow: 0 0 2px #e00000, 2px 2px 16px hsla(0,0%,100%,.5);
}

.about-window hr {
    width: auto;
    margin: 20px 50px 20px 50px;
    border: 2px dotted brown;
}

.my-name {
    font-family: "Fake Receipt", monospace;
    color: #a3ff65;
    text-shadow: 0 0 2px #007704, 2px 2px 16px hsla(0,0%,100%,.5);
    text-align: center;
    font-size: 20px;
    padding: 2px 3px;
    background: black;
}

.what-i-am {
    font-family: "Fake Receipt", monospace;
    color: #ed77ff;
    text-shadow: 0 0 2px #ffffff, 2px 2px 16px hsla(0,0%,100%,.5);
    font-size: 20px;
    padding: 2px 3px;
    background: black;
}

.what-i-am2 {
    font-family: "Fake Receipt", monospace;
    color: #ffa144;
    text-shadow: 0 0 2px #ffb175, 2px 2px 16px hsla(0,0%,100%,.5);
    font-size: 20px;
    padding: 2px 3px;
    background: black;
}

.my-website {
    font-family: "Fake Receipt", monospace;
    color: #75f8ff;
    text-shadow: 0 0 2px #005b03, 2px 2px 16px hsla(0,0%,100%,.5);
    text-align: center;
    font-size: 20px;
    text-decoration: underline;
    padding: 2px 3px;
    background: black;
}

.my-website:hover {
    cursor: pointer;
}

.ambu-color {
    color:#b0190c;
    text-shadow: 0 0 2px #9b0000, 2px 2px 16px hsla(0,0%,100%,.5);
}

.close-about-btn {
    position: fixed;
    top: 20px;
    right: 20px;
    height: 50px;
    width: 50px;
	cursor: pointer;
    border-radius: 4px;
    font-size: 36px;
    color: #fff;
    text-align: center;
}

@media screen and (max-width: 610px) {
    .about-window {
        height: auto;
    }


    .about-window p {
        margin: 0;
    }
}

/* 
body:before {
    height: 100%;
    background: linear-gradient(rgba(18,16,16,0) 50%,rgba(0,0,0,.25) 0),linear-gradient(90deg,rgba(255,0,0,.06),rgba(0,255,0,.02),rgba(0,0,255,.06));
    background-size: 100% 2px,3px 100%;
} */

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}

.program-art-page {
    height: auto;
    padding: 20px 0 100px 0;
    margin: 0 auto;

    background-image: linear-gradient(var(--bggc) .1em, transparent .1em), linear-gradient(90deg, var(--bggc) .1em, transparent .1em);
    background-size: 1.6em 1.6em;
    background-attachment: fixed;
}

/* .program-art-page:before { */
.page-filter {
    content: " ";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(rgba(18,16,16,0) 50%,rgba(0, 0, 0, 0.137) 0),linear-gradient(90deg,rgba(255,0,0,.06),rgba(0,255,0,.02),rgba(0,0,255,.06));
    background-size: 100% 2px,3px 100%;
}

.background-none {
    background: none;

    .program-art-page::before {
        background: none;
    }
}

.header-wrap {
    width: 100%;
    margin: 0 auto;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: linear-gradient(0deg, rgba(129,129,129,1) 0%, rgba(210,210,210,1) 15%, rgba(245,245,245,1) 50%, rgba(210,210,210,1) 85%, rgb(129, 129, 129) 100%);
}

.header-coin-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60px;
    height: 60px;
    margin: auto auto auto auto;
    transform-style: preserve-3d;
}

.left-header-wrap {
    width: 7%;

    margin: 10px auto 10px auto;
    display: flex;

    background: #000;
    background-image: linear-gradient(#5f0000 2px, transparent 2px), 
    linear-gradient(90deg, #5f0000 2px, transparent 2px);
    background-size: 0.5em 0.5em;
    background-position: -10% -10%;
    border: 3px inset #fff;
    outline: #5f0000 2px inset;
    border-radius: 20%;
}

.coin-left {
    animation: coin-left 2.5s infinite;
    animation-timing-function: linear;
}

.coin-right {
    animation: coin-right 2.5s infinite;
    animation-timing-function: linear;
}

.coin-left:hover {
    filter:sepia(1);
    cursor: pointer;
}

.coin-right:hover {
    filter:sepia(1);
    cursor: pointer;
}

.coin-face {
    position: absolute;
    width: 100%;
    height: 100%;
    /* line-height: 260px; */
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 40px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.header-coin-front {
    background: radial-gradient(goldenrod, gold);
    border: 2px dashed goldenrod;
    border-radius: 100%;
}

.header-coin-back {
    background: radial-gradient(rgb(42, 42, 255), rgb(178, 236, 255));
    border: 2px dashed rgb(42, 42, 255);
    border-radius: 100%;
    transform: rotateY(180deg);
}

.coin-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-family: "Fake Receipt", monospace;
    font-size: 48px;
    font-weight: 100;
    color: #12fe8d;
    text-shadow: 0 0 2px #00cb07, 2px 2px 16px hsla(0,0%,100%,.5);
    text-align: center;
    margin: auto;
}

.text-purp {
    color: rgb(249, 203, 255);
}

@keyframes coin-left {
    from {
        transform: rotateY(0);
    }
    
    to {
        transform: rotateY(360deg);
    }
  }

  @keyframes coin-right {
    from {
        transform: rotateY(180deg);
    }
    
    to {
        transform: rotateY(-180deg);
    }
  }

.about-info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin: auto 0 auto auto;
    background: radial-gradient(goldenrod, gold);
    border: 2px dashed rgb(86, 86, 86);
    border-radius: 100%;
    filter: sepia(1);

    animation: rotateZ 10s infinite;
    animation-timing-function: linear;
}

.about-info-wrapper:hover {
    filter:saturate(1);
    cursor: pointer;
}

.about-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-family: "Fake Receipt", monospace;
    font-size: 40px;
    font-weight: 100;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 2px #5e5e5e, 2px 2px 16px hsla(0,0%,100%,.5);
    text-align: center;
    margin: auto;
}

@keyframes rotateZ {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);
    }
}

.title-wrap {
    width: 72%;
    /* height: auto; */
    height: auto;
    display: flex;
    flex-direction: row;

    display: flex;
    justify-content: space-between;
    position: relative;
    /* padding: 24px 16px; */
    /* gap: 16px; */
    background-color: #fff;
    background-image: linear-gradient(180deg,#ffd17d,#ffedae 47%, #ffd17d 53%,#ffdc5d);
    border-radius: 20px;
    margin: 16px auto;
    /* background-color: rgba(75, 0, 0, 0.658); */
    border: 4px outset #6f7568;
    outline: 2px solid #fff;
    box-sizing: border-box;
}

.hbolt-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
}

.hbolt {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    /* margin: 10px; */
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);

    background: rgb(236,236,236);
    background: radial-gradient(circle, rgb(255, 255, 255) 0%, #ffdb5a 38%, rgb(121, 121, 121) 93%);
}

.rivot {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    /* margin: 10px; */
    /* border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2); */

    filter: drop-shadow(-1px 1px 3px rgba(0,0,0,0.6));    
    background-position: center;
    background-size: contain;
    background-image: url(../images/bronzerivot.webp);
}


.lightboxes {
    display: flex;
    gap: 4px;
    margin: auto 20px;
    padding: 8px 0;
    height: auto; 
    border-top: #00ff07 2px solid;
    border-bottom: #00ff07 2px solid;
}

.lbox1 {
    width: 24px;
    height: 24px;
    box-shadow: inset 0 0 20px #00e4fa;
    background-color: #00e4fa;
    background: radial-gradient(#00e4fa,#20b1f0);
    border-color: rgba(32,177,240,.25);
    border-style: inset;
    border-width: 3px 3px 2px 2px;
    margin: auto 0;
    animation: flicker1 10s infinite alternate;
}
.lbox2 {
    width: 24px;
    height: 24px;
    box-shadow: inset 0 0 10px #00a806;
    background-color: #00a806;
    background: radial-gradient(#00a806,#276621);
    border-color: rgba(39,102,33,.25);
    border-style: inset;
    border-width: 3px 3px 2px 2px;
    margin: auto 0;
    animation: flicker2 10s infinite alternate;
}

.lbox3 {
    width: 24px;
    height: 24px;
    box-shadow: inset 0 0 10px #ffc106;
    background-color: #f5bd16;
    background: radial-gradient(#f5bd16,rgb(255, 205, 40));
    border-color: rgba(255, 115, 0, 0.25);
    border-style: inset;
    border-width: 3px 3px 2px 2px;
    margin: auto 0;
    animation: flicker3 10s infinite alternate;
}

.lbox4 {
    width: 24px;
    height: 24px;
    box-shadow: inset 0 0 10px #ff1900;
    background-color: #ff1900;
    background: radial-gradient(#ff1900,#7a2018);
    border-color: rgba(122,32,24,.25);
    border-style: inset;
    border-width: 3px 3px 2px 2px;
    margin: auto 0;
    animation: flicker4 10s infinite alternate;
}

.double-vision {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 14px;
    margin: 10px;
}


.title-text {
    font-family: "Dot Font", monospace;
    font-size: 2rem;
    width: 100%;
    /* font-size: 35px; */
    letter-spacing: 2px;
    background-color: #000;
    border-radius: 16px;
    /* margin: 20px 16px 20px 20px; */
    /* margin: 10px 10px 10px 10px; */
    /* background-color: rgb(255, 231, 201);
    box-shadow:  -8px -6px #00A4BD, 6px 6px #FF7A59; */
    border: 4px outset #c6d1bc;
    outline: 2px solid #fff;
    display: flex;
    flex-direction: row;
    /* padding: 16px 0 10px 0; */
    padding: 16px 0 10px 0;
    box-sizing: border-box;
    overflow: hidden;
}

.title-span2 {
    float: left;
    color: #12e2fe;
    text-shadow: 0 0 4px #00ff07, 2px 2px 16px hsla(0,0%,100%,.5);
    padding-left: 100%;
    white-space: nowrap;
    animation: marquee 26s linear infinite reverse;
}

.title-span1 {
    float: left;
    color: #12fe8d;
    text-shadow: 0 0 4px #00ff07, 2px 2px 16px hsla(0,0%,100%,.5);
    padding-left: 100%;
    white-space: nowrap;
    animation: marquee 26s linear infinite;
}

@keyframes marquee {
    0% {
        transform: translate(0);
    }
    
    100% {
        transform: translate(-105%);
    }
  }

  @keyframes flicker {
    0% {
        filter: brightness(1);
    }
    74% {
        filter: brightness(1);
    }
    75% {
        filter: brightness(.5);
    }
    78% {
        filter: brightness(1);
    }
    79% {
        filter: brightness(.5);
    }
    99% {
        filter: brightness(.5);
    }
    100% {
        filter: brightness(1);
    }
  }

  @keyframes flicker1 {
    0% {
        filter: brightness(0);
        box-shadow: none;
    }
    15% {
        filter:brightness(1);
        box-shadow: 0 0 6px #00e4fa;
    }
    74% {
        filter: brightness(1);
        box-shadow: 0 0 6px #00e4fa;
    }
    75% {
        filter: brightness(.5);
        box-shadow: none;
    }
    78% {
        filter: brightness(1);
        box-shadow: 0 0 6px #00e4fa;
    }
    79% {
        filter: brightness(.5);
        box-shadow: none;
    }
    99% {
        filter: brightness(.5);
        box-shadow: none;
    }
    100% {
        filter: brightness(1);
        box-shadow: 0 0 6px #00e4fa;
    }
  }

  @keyframes flicker2 {
    0% {
        filter: brightness(0);
        box-shadow: none;
    }
    35% {
        filter:brightness(1);
        box-shadow: 0 0 6px #00a806;
    }
    74% {
        filter: brightness(1);
        box-shadow: 0 0 6px #00a806;
    }
    75% {
        filter: brightness(.5);
        box-shadow: none;
    }
    78% {
        filter: brightness(1);
        box-shadow: 0 0 6px #00a806;
    }
    79% {
        filter: brightness(.5);
        box-shadow: none;
    }
    99% {
        filter: brightness(.5);
        box-shadow: none;
    }
    100% {
        filter: brightness(1);
        box-shadow: 0 0 6px #00a806;
    }
  }

  @keyframes flicker3 {
    0% {
        filter: brightness(0);
        box-shadow: none;
    }
    65% {
        filter:brightness(1);
        box-shadow: 0 0 6px #ffc106;
    }
    74% {
        filter: brightness(1);
        box-shadow: 0 0 6px #ffc106;
    }
    75% {
        filter: brightness(.5);
        box-shadow: none;
    }
    78% {
        filter: brightness(1);
        box-shadow: 0 0 6px #ffc106;
    }
    79% {
        filter: brightness(.5);
        box-shadow: none;
    }
    99% {
        filter: brightness(.5);
        box-shadow: none;
    }
    100% {
        filter: brightness(1);
        box-shadow: 0 0 6px #ffc106;
    }
  }

  @keyframes flicker4 {
    0% {
        filter: brightness(0);
        box-shadow: none;
    }
    74% {
        filter: brightness(1);
        box-shadow: 0 0 6px #ff1900;
    }
    75% {
        filter: brightness(.5);
        box-shadow: none;
    }
    78% {
        filter: brightness(1);
        box-shadow: 0 0 6px #ff1900;
    }
    79% {
        filter: brightness(.5);
        box-shadow: none;
    }
    99% {
        filter: brightness(.5);
        box-shadow: none;
    }
    100% {
        filter: brightness(1);
        box-shadow: 0 0 6px #ff1900;
    }
  }


.art-windows {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
}

.art-card-wrapper {
    --acw: 400px;

    width: auto;
    height: auto;
    position: relative;
    /* width: var(--acw); */
    /* object-fit: contain; */
    margin: 10px;
}

.old-paper-bg {
    width: var(--acw);
    margin: auto;
    height: fit-content;
    position: absolute;
}

.art-image {
    width: var(--acw);
    max-width: 95vw;
    height: fit-content;
    /* object-fit: contain; */
    /* position: absolute; */
    position: relative;
    border-radius: 10px;
    box-shadow: -7px 4px rgba(0, 0, 0, 0.692);
    transform-style: preserve-3d;
    transition: transform 0.5s ease-in-out;
}

.art-image:hover {
    transform-origin: center;
    transform: scale(1.15);
}

.tack {
    width: 50px;
    position: absolute;
    left: 50%;
    translate: 0 -35%;
}

.art-card {
    /* width: 100%; */
    height: max-content;
    /* background: url(../images/oldpaper1.png); */
    background-size:cover;
    background-repeat: no-repeat;
}

.tile-drawer-wrap {
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tile {
    width: 15px;
    height: 15px;
    background: url(../images/tile.png);
    background-position: center;
    background-size: contain;
}

.tile-ani {
    animation: tileEffect 1.5s ease-out;
}

@keyframes tileEffect {
    from {
        filter: hue-rotate(540deg) saturate(20) contrast(15);
    }
    to {
        filter: none;
    }
}

@media screen and (min-width: 1401px) {
    .about-window {
        height: auto;
        margin: 0 auto;
    }

    .about-window h2, h3, h4, h5, h6, p {
        font-size: 3rem;
    }
}


@media screen and (max-width: 1400px) {
    .about-window {
        height: auto;
        margin: 0 auto;
    }

    .double-vision {
        width: 80%;
    }

    .lightboxes {
        width: 100px;
        margin-left: auto;
        margin-right: auto;
        box-sizing: border-box;
    }

    .lbox1, .lbox2, .lbox3, .lbox4 {
        margin: auto;
        max-width: 24px;
        width: 100%;
    }
}

@media screen and (max-width: 1000px) {
    .double-vision {
        width: 70%;
    }
}


@media screen and (max-width: 725px) {

    .header-coin-wrap, .coin-left {
        display: none;
    }

    .left-header-wrap {
        display: none;
    }

    .about-info-wrapper {
        margin: auto 0 auto 20px;
    }

}

@media screen and (max-width: 600px) {
    .header-coin-wrap, .coin-left {
        display: none;
    }
    
    .about-window {
        /* border: 4px dashed black; */
        /* background: radial-gradient(#D9BD94 60%, #9D633D); */
        box-sizing: border-box;
        padding: 30px;
        padding: 0;
        width: 90%;
        height: auto;
        color: white;
        /* height: 90%; */
        justify-content: space-evenly;
        background-color: black;
        background-image: linear-gradient(#ff6e6e33 2px, transparent 2px), linear-gradient(90deg, #ff6e6e33 2px, transparent 2px);
        background-repeat: repeat;
        background-size: 0.5em 0.5em;
        background-position: -10% -10%;
        border: 3px inset #ffffff;

    }

    .about-window h5 {
        color: white;
    }

    .about-coverpage-wrapper {
        height: 100%;
    }

    .about-window h2 {
        font-size: 2rem;
    }

    .about-window p {
        text-shadow: 0 0 2px #000000, 2px 2px 16px hsla(0,0%,100%,.5);
        font-size: 1.5rem;
        margin: 0 0px;
    }

    .my-name {
        font-size: 12px;
    }

    .my-website {
        font-size: 12px;
    }

    .what-i-am, .what-i-am2 {
        font-size: 12px;
    }

    .thank-you {
        font-size: 12px;
        margin: 0;
    }


    .title-wrap {
        width: 70%;
    }

    .title-text {
        font-size: 28px;
        margin-right: 0;
    }
    
    .lightboxes {
        transform: scale(0.8);
        margin-right: 0;
    }
 }

 @media screen and (max-width: 535px) {
    .about-coverpage-wrapper {
        height: 100%;
    }

    .about-info-wrapper {
        animation: none;
        margin: auto 0;
        border-radius: 0;
        width: 40px;
        height: 40px;
    }

    .about-info {
        font-size: 30px;
    }

    .title-wrap {
        /* width: 60%; */
        margin: 16px 6px 16px 0;
    }

    .double-vision {
        width: 64%;
    }

    .lightboxes {
        margin-right: 10px;
        height: 70%;

        border-top: #00ff07 3px solid;
        border-bottom: #00ff07 3px solid;
        padding: 4px 0;
    }

    .lbox1, .lbox2, .lbox3, .lbox4 {
        max-width: 14px;
        height: 80%;
    }


    .rivot {
        width: 14px;
        height: 14px;
    }

    @keyframes rotateZ {
        from {
            transform: rotateZ(0deg) scale(1);
        }
        to {
            transform: rotateZ(360deg) scale(1.2);
        }
    }
 }

 .senior-bumpis {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
 }