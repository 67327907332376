@font-face {
    font-family: Dot Font;
    src: url(../../../fonts/dotfont.ttf) format('truetype');
  }

.marquee-wrap {
    font-family: "Dot Font", monospace;
    font-size: 3rem;
    width: 100%;
    font-size: 35px;
    letter-spacing: 2px;
    background-color: #000;
    /* background-color: rgb(255, 231, 201);
    box-shadow:  -8px -6px #00A4BD, 6px 6px #FF7A59; */
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 300px;
    max-width: 400px;
}

.marquee-inner {
    display: flex;
    flex-direction: row;
    width: 200%;
    /* position: absolute; */
    background-color: black;
}

.marquee-inner:hover {
    cursor: pointer;
    filter: invert(1);
    
    .marquee-left {
        animation-play-state: paused;
        color: rgb(255, 67, 67);
        text-shadow: 0 0 4px rgb(255, 67, 67), 2px 2px 16px hsla(0,0%,100%,.5);
    }

    .marquee-right {
        animation-play-state: paused;
        color: rgb(255, 67, 67);
        text-shadow: 0 0 4px rgb(255, 67, 67), 2px 2px 16px hsla(0,0%,100%,.5);
    }
}


.double-up {
    display: flex;
    flex-direction: row;
}

.marquee-left {
    color: #fefa12;
    text-shadow: 0 0 4px #fefa12, 2px 2px 16px hsla(0,0%,100%,.5);
    white-space: nowrap;
    float:left;
    width: auto;
    animation: marquee-to-left 20s linear infinite;
}

.marquee-right {
    color: #f476ff;
    text-shadow: 0 0 4px #f476ff, 2px 2px 16px hsla(0,0%,100%,.5);
    white-space: nowrap;
    float:left;
    width: auto;
    animation: marquee-to-left 20s linear reverse infinite;
}

@keyframes marquee-to-left {
    0% {
        transform: translate(0);
    }
    
    100% {
        transform: translate(-100%);
    }
  }